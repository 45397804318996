import { useCallback, useEffect, useState } from "react"
import { DashboardTopBar } from "../templates/Dashboard"
import FilterAction from "../components/Filter/FilterAction"
import FilterOrderCard from "../components/Filter/FilterOrderCard";
import FilterGrid from "../components/Filter/FilterGrid";
import FilterGridCell from "../components/Filter/FilterGridCell";
import FilterImageBox from "../components/Filter/FilterImageBox";
import config from "../config";

const ENV = config.ENV;
const API_BASE = ENV === "production" ? config.API_BASE_PROD : config.API_BASE_DEV;

function OrdersSection() {

  const [orders, setOrders] = useState([]);
  const [isAttachmentVisible, setIsAttachmentVisible] = useState(false);
  const [attachmentSrc, setAttachmentSrc] = useState("");

  const fetchOrders = useCallback(async () => {
    const target = API_BASE + "/orders/mine";
    let fethcedOrders;
    try {
      fethcedOrders = (await fetch(target)).json();
    } catch (error) {
      console.log(error);
    }

    if ((await fethcedOrders).error) {
      return console.log((await fethcedOrders).error);
    }
    setOrders(await fethcedOrders);
  }, [])

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  const exportHandler = (orderId) => {
    setOrders((prevOrders) => {
      const newOrders = [...prevOrders];
      const order = newOrders.find(o => o.orderId === orderId);
      order.isLoading = true;
      return newOrders;
    })
    fetch(API_BASE + "/leads/export", {
      method: "post",
      body: JSON.stringify({ orderId: orderId }),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        fetchOrders();
      });
  }

  const downloadHandler = (order, isCsv) => {
    const params = new URLSearchParams();
    params.set("orderId", order.orderId);
    isCsv && params.set("type", "csv");
    const linkElement = document.createElement("a");
    linkElement.setAttribute("download", "");
    linkElement.href = API_BASE + "/leads/download/?" + params.toString();
    console.log(linkElement.href);
    document.body.appendChild(linkElement);
    linkElement.click();
    linkElement.remove();
  }

  const onShowAttachment = (order) => {
    const params = new URLSearchParams();
    params.set("orderId", order.orderId);
    const src = API_BASE + "/orders/attachment/?" + params.toString();
    
    if (order.attachment.extension === "pdf") {
      onViewAttachment(src);
      return;
    }
    setAttachmentSrc(src);
    setIsAttachmentVisible(true);
  }

  const onViewAttachment = (src) => {
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = src;
    a.click();
  }

  const onMarkAsPaid = (order) => {
    setOrders((prevOrders) => {
      const newOrders = [...prevOrders];
      const foundOrder = newOrders.find(o => o.orderId === order.orderId);
      foundOrder.isLoading = true;
      return newOrders;
    })
    const target = API_BASE + "/orders/confirm/";
    fetch(target, {
      method: "post",
      body: JSON.stringify({
        orderId: order.orderId
      }),
      headers: {
        "content-type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setOrders((prevOrders) => {
            const newOrders = [...prevOrders];
            const foundOrder = newOrders.find(o => o.orderId === order.orderId);
            foundOrder.isLoading = false;
            return newOrders;
          })
          return console.log(res.error);
        } else {
          console.log(res.success);
        }
        fetchOrders();
      })
      .catch((error) => {
        console.log(error.message);
        setOrders((prevOrders) => {
          const newOrders = [...prevOrders];
          const foundOrder = newOrders.find(o => o.orderId === order.orderId);
          foundOrder.isLoading = false;
          return newOrders;
        })
      })
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "calc(100% - 260px)", height: "100%" }}>
      <DashboardTopBar>
        <h2 style={{ marginRight: "auto" }}>Orders</h2>
        <FilterAction title="new order" onClick={() => { window.location.pathname = "/dashboard/orders/new" }} />
      </DashboardTopBar>
      <div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
        <div style={{ width: "100%", height: "100%", overflow: "auto", padding: "20px", "--scrollbar-filter-bg": "var(--filter-ui-93)" }}>
          <FilterGrid gutter={10} maxColumns={3}>
            {
              orders?.map((order) => (
                <FilterGridCell size={1} key={order.orderId}>
                  <FilterOrderCard
                    key={order.orderId}
                    order={order}
                    onExport={exportHandler}
                    onDownload={downloadHandler}
                    onMarkAsPaid={onMarkAsPaid}
                    onViewAttachment={onShowAttachment}
                  />
                </FilterGridCell>
              ))
            }
          </FilterGrid>
        </div>
        {
          isAttachmentVisible &&
          <div style={{ width: "fit-content", height: "100%", overflow: "auto", backgroundColor: "var(--filter-ui-95)", flexShrink: "0", padding: "10px", borderLeft: "1px solid var(--filter-ui-99)" }}>
            <div style={{ display: "flex", gap: "5px" }}>
              <FilterAction variation="blue" title={"hide"} onClick={() => setIsAttachmentVisible(false)} />
              <FilterAction variation="blue" title={"view"} onClick={() => onViewAttachment(attachmentSrc)} />
            </div>
            <FilterImageBox src={attachmentSrc} maxHeight={"300px"} maxWidth={"300px"} />
          </div>
        }
      </div>
    </div>
  )
}

export default OrdersSection