import "./FilterSelectThumb.scss";

const FilterSelectThumb = ({ options, focusOptionValue, onSelect }) => {
  return (
    <div className='filter-comp-select-thumb'>
      {
        options.map(o => (
          <button className={focusOptionValue === o.value ? 'option selected' : "option"} onClick={() => onSelect(o.value)}>
            <div className="thumbnail">
              <img src={o.thumbnailSrc} alt={`${o.title} thumbnail`} />
            </div>
            <div className="title">{o.title}</div>
          </button>
        ))
      }
    </div>
  )
}

export default FilterSelectThumb;