const config = {
  ENV: "production",
  API_BASE_DEV: "http://localhost",
  API_BASE_PROD: window.location.protocol + '//' + window.location.hostname,
  STRIPE_PK_LIVE: "pk_test_51PL47eP6PigdnD7b5ruT1FLLFBxOwB50D9Pzk8ny5ws8rBneKwEiAiwMubNwhW7oB3y0eaSQRkNXrT3ivWJ5cCmJ006ubJNMeb",
  navLinks: [
    {
      title: "My Orders",
      path: "/dashboard/orders",
      isExact: true
    },
    {
      title: "All Orders",
      path: "/dashboard/orders/all",
      isAdminRoute: true
    },
  ]
}

export default config;