import { useRef, useState } from "react";
import FilterButton from "./FilterButton";
import "./FilterNumberInput.scss"
import { ReactComponent as ArrowRightIcon } from "./icon-arrow-right-6.svg"
import { ReactComponent as ArrowLeftIcon } from "./icon-arrow-left-6.svg"

function FilterNumberInput({ value, min, max, onChange }) {

  const inputRef = useRef();
  const classList = ["comp-filter-number-input"];
  const [isInputFocus, setIsInputFocus] = useState(false);
  const randomId = useRef(crypto.randomUUID());
  isInputFocus && classList.push("focus");

  return (
    <div className={classList.join(" ")}>
      <input
        id={randomId.current}
        className="input"
        ref={inputRef}
        type="number"
        min={min}
        max={max}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        // onChange={(e) => {
        //   (
        //     (parseInt(e.target.value) > min || parseInt(e.target.value) === min)
        //     && (parseInt(e.target.value) < max || parseInt(e.target.value) === max)
        //   ) ? onChange(parseInt(e.target.value)) : onChange(value)
        // }}
        onFocus={() => { setIsInputFocus(true) }}
        onBlur={() => { setIsInputFocus(false) }}
      />
      <div className='buttons'>
        <FilterButton
          icon={<ArrowLeftIcon />}
          noRadiusRight
          disabled={(value < min || value === min)}
          onClick={() => { (value - 1 > min || value - 1 === min) ? onChange(parseInt(inputRef.current.value) - 1) : onChange(value) }}
          // onClick={() => { onChange(inputRef.current.value - 1) }}
        />
        <div className="separator"></div>
        <FilterButton
          icon={<ArrowRightIcon />}
          noRadiusLeft
          disabled={(value > max || value === max)}
          onClick={() => { (value + 1 < max || value + 1 === max) ? onChange(parseInt(inputRef.current.value) + 1) : onChange(value) }}
          // onClick={() => { onChange(inputRef.current.value + 1) }}
        />
      </div>
    </div>
  )
}

export default FilterNumberInput