import { useState } from "react"
import FilterAction from "../components/Filter/FilterAction";
import FilterInput from "../components/Filter/FilterInput";
import FilterFolder from "../components/Filter/FilterFolder";
import { ReactComponent as HexaPinkLogoFull } from "../assets/logo/hxp-logo-full.svg";
import config from "../config";
import FilterFormMessage from "../components/Filter/FilterFormMessage";
import RegisterSection from "./RegisterSection";
import { PiPassword, PiUser } from "react-icons/pi";

const ENV = config.ENV;
const API_BASE = ENV === "production" ? config.API_BASE_PROD : config.API_BASE_DEV;

function LoginPage() {
  const [loginUsername, setLoginUsername] = useState("")
  const [loginPassword, setLoginPassword] = useState("")
  const [loginErrorMessage, setLoginMessage] = useState({ type: "", message: "" });
  const [selectedTab, setSelectedTab] = useState("login")

  const loginSubmitHandler = () => {
    fetch(API_BASE + "/auth/login", {
      method: "post",
      body: JSON.stringify({ username: loginUsername, password: loginPassword }),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          window.location.pathname = "/dashboard/orders"
        } else {
          setLoginMessage({ type: "error", message: res.error });
          setTimeout(() => {
            setLoginMessage({ type: "", message: "" });
          }, 2000)
        }
      })
  }

  return (
    <div style={{ backgroundColor: "var(--filter-ui-93)", display: "flex", flexDirection: "column", gap: "30px", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }}>
      <HexaPinkLogoFull />
      <div style={{ width: "300px" }}>
        <FilterFolder noBorderRadiusTopRight selectedOptionValue={selectedTab} options={[{ title: "Login", value: "login" }, { title: "Register", value: "register" }]} onToggle={(value) => { setSelectedTab(value) }}>
          {
            selectedTab === "login" &&
            <form onSubmit={(e) => { e.preventDefault() }} style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
              <FilterInput icon={<PiUser />} type="text" value={loginUsername} onChange={(value) => { setLoginUsername(value) }} placeholder={"username"} />
              <FilterInput icon={<PiPassword />} type="password" value={loginPassword} onChange={(value) => { setLoginPassword(value) }} placeholder={"password"} />
              <FilterAction title={"login"} onClick={loginSubmitHandler} />
              {
                loginErrorMessage.message &&
                <FilterFormMessage message={loginErrorMessage.message} type={loginErrorMessage.type} />
              }
            </form>
          }
          {
            selectedTab === "register" &&

            <RegisterSection />
          }
        </FilterFolder>
      </div>
    </div>
  )
}

export default LoginPage