import { useCallback, useEffect, useMemo, useState } from "react";
import { DashboardTopBar } from "../templates/Dashboard";
import FilterFolder from "../components/Filter/FilterFolder";
import FilterGroup from "../components/Filter/FilterGroup";
import FilterParser, { getClearedFilter } from "../components/Filter/FilterParser";
import FilterCollapsable from "../components/Filter/FilterCollapsable";
import FilterCard from "../components/Filter/FilterCard";
import FilterList from "../components/Filter/FilterList";
import FilterRange from "../components/Filter/FilterRange";
import FilterTag from "../components/Filter/FilterTag";
import FilterAction from "../components/Filter/FilterAction";
import FilterLoadingCard from "../components/Filter/FilterLoadingCard";
import FilterFormMessage from "../components/Filter/FilterFormMessage";
import FilterInput from "../components/Filter/FilterInput";
import FilterLabel from "../components/Filter/FilterLabel";
import FilterSelectOption from "../components/Filter/FilterSelectOption";
import { PiCalendar, PiMapPin } from "react-icons/pi";
import config from "../config";

const ENV = config.ENV;
const API_BASE = ENV === "production" ? config.API_BASE_PROD : config.API_BASE_DEV;

const validateBirthDate = (v) => {
  const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4})$/;
  return regex.test(v) && (v.length === 10);
}

function NewOrderSection() {

  const [selectedTab, setSelectedTab] = useState("location");
  const [selectedRecapTab, setSelectedRecapTab] = useState("order");
  const [maxVolume, setMaxVolume] = useState(0);
  const [volume, setVolume] = useState(0);
  const [PRICE_CPM, setCostPerMile] = useState(0);
  // const [optionalLabels, setOptionalLabels] = useState([
  //   { title: "Email", value: "email" },
  //   { title: "Phone", value: "phone" },
  // ]);

  const [filters, setFilters] = useState(null);
  const [filter, setFilter] = useState(null);
  const [prevFilter, setPrevFilter] = useState(null);
  const [leadSelectCriteria, setLeadSelectCriteria] = useState(null);
  const [locationCriteria, setLocationCriteria] = useState(null);
  const [criteriaToTitleMap, setCriteriaToTitleMap] = useState(null);
  const [startDate, setStartDate] = useState("01-01-1900");
  const [endDate, setEndDate] = useState("01-01-2100");
  const [startZip, setStartZip] = useState(0);
  const [endZip, setEndZip] = useState(100000);

  const onStartDateChange = (v) => {
    setStartDate(v);
  }

  const onEndDateChange = (v) => {
    setEndDate(v);
  }

  const onStartZipChange = (v) => {
    if (isVolumeUpdating) {
      return;
    }
    setStartZip(isNaN(parseInt(v)) ? 0 : parseInt(v));
  }

  const onEndZipChange = (v) => {
    if (isVolumeUpdating) {
      return;
    }
    setEndZip(isNaN(parseInt(v)) ? 100000 : parseInt(v));
  }

  const leadTypeFiltersValue = [
    {
      name: "lead-type",
      title: "Lead Type",
      valueType: "string",
      filterType: "radio-select",
      filterStatus: 0,
      isParentFilter: true,
      filterData: {
        focusOptionValue: "business",
        options: [
          { title: "business", value: "business" },
          { title: "customer", value: "customer" },
        ]
      }
    },
    {
      name: "country",
      title: "Country",
      valueType: "string",
      filterType: "radio-select",
      filterStatus: 0,
      isParentFilter: true,
      filterData: {
        focusOptionValue: "belgium",
        options: [
          { title: "Belgium", value: "belgium" },
          { title: "France", value: "france" },
        ]
      }
    }
  ];
  const [leadTypeFilters, setLeadTypeFilters] = useState(leadTypeFiltersValue);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [isVolumeUpdating, setIsVolumeUpdating] = useState(false);

  // setFilters only if theur value didn't change
  useEffect(() => {
    if (JSON.stringify(prevFilter) !== JSON.stringify(filter)) {
      setPrevFilter(filter);
    }
  }, [filter, prevFilter])

  const recapLeadType = useMemo(() => (
    {
      country: leadTypeFilters.find(f => f.name === "country").filterData.focusOptionValue,
      leadType: leadTypeFilters.find(f => f.name === "lead-type").filterData.focusOptionValue,
    }
  ), [leadTypeFilters])

  const getReducedData = (country, leadType) => {
    if (!(country && leadType)) {
      return;
    }
    setFiltersLoading(true);
    const params = new URLSearchParams();
    params.set("country", country);
    params.set("leadType", leadType);
    fetch(API_BASE + "/leads/reduce/?" + params.toString())
      .then(res => res.json())
      .then(res => {
        const newFilters = [];
        const uniqueCriteria = res.filters;
        const resLocationCriteria = res.locationCriteria;
        setLocationCriteria(res.locationCriteria);
        setLeadSelectCriteria(res.leadSelectCriteria);
        setCriteriaToTitleMap(res.criteriaToTitleMap);
        Object.entries(uniqueCriteria).forEach(criteria => {
          const parentFilterName = resLocationCriteria.indexOf(criteria[0]) > 0 ? resLocationCriteria[resLocationCriteria.indexOf(criteria[0]) - 1] : null;
          newFilters.push({
            name: criteria[0],
            title: res.criteriaToTitleMap[criteria[0]] || criteria[0],
            valueType: "string",
            filterType: "multi-select",
            filterStatus: 0,
            filterData: {
              focusOptionValue: criteria[0] === "key_country" ? JSON.stringify(criteria[1][0]) : null,
              // parentFilterName: parentFilterName,
              // isParentFilter: resLocationCriteria.slice(0, resLocationCriteria.length - 1).includes(criteria[0]),
              options: criteria[1].map(value => {
                let parentValue = null
                if ((value instanceof Object)) {
                  parentValue = value;
                  parentValue = Object.entries(parentValue).filter(entry => entry[0] !== criteria[0]);
                  parentValue = JSON.stringify(Object.fromEntries(parentValue));
                }
                return {
                  title: (value instanceof Object) ? value[criteria[0]] : value,
                  value: (value instanceof Object) ? JSON.stringify(value) : value,
                  parentValue: parentValue,
                  isSelected: criteria[0] === "key_country"
                }
              })
            }
          });
        });
        setFilters(newFilters);
        setFiltersLoading(false);
      })
  }

  // fetch reduced data
  useEffect(() => {
    getReducedData(recapLeadType.country, recapLeadType.leadType);
  }, [recapLeadType])

  // calc recap
  const recapData = useMemo(() => {
    const output = {
      location: Object.fromEntries(
        locationCriteria?.map(l => {
          if (l === "key_c_zip") {
            const start = startZip || 0;
            const end = endZip || 100000;
            return [l, { start, end }]
          }
          return [l, filters?.find((f) => (f.name === l))?.filterData.options.filter(o => o.isSelected)]
        })
        || []
      ),
      lead: Object.fromEntries(
        leadSelectCriteria?.map(l => {
          if (l === "key_c_birth_date") {
            const start = validateBirthDate(startDate) ? startDate : "01-01-1900";
            const end = validateBirthDate(endDate) ? endDate : "01-01-2100";
            return [l, { start, end }]
          }
          return [l, filters?.find((f) => (f.name === l))?.filterData.options.filter(o => o.isSelected)]
        })
        || []
      ),
    }
    return output;
  }, [filters, leadSelectCriteria, locationCriteria, startDate, endDate, startZip, endZip])

  const recapTabs = [
    {
      title: "Recap",
      value: "recap",
    },
    {
      title: "Order",
      value: "order",
    }
  ]

  const criteriaTabs = [
    {
      title: "Location",
      value: "location",
      filters: locationCriteria
    },
    {
      title: "Lead",
      value: "lead",
      filters: leadSelectCriteria
    }
  ]

  // update filter

  const updateFilter = useCallback(() => {
    if (!(Object.entries(recapData.location || {}).length || Object.entries(recapData.data || {}).length)) {
      return;
    }
    const newFilter = {
      location: Object.fromEntries(
        Object.entries(recapData.location)
          .map(entry => ([entry[0], entry[0] !== "key_c_zip" ? entry[1].map(v => v.value) : entry[1]]))
          .filter(entry => (entry[0] === "key_c_zip") || entry[1].length)
      ),
      data: Object.fromEntries(
        Object.entries(recapData.lead)
          .map(entry => ([entry[0], entry[0] !== "key_c_birth_date" ? entry[1].map(v => v.value) : entry[1]]))
          .filter(entry => (entry[0] === "key_c_birth_date") || (entry[1].length))
      )
    }
    setFilter(newFilter);
  }, [recapData])

  useEffect(() => {
    updateFilter();
  }, [updateFilter])

  // toggle include exported

  const [excludeExported, setExcludeExported] = useState(true);

  const onToggleExcludeExported = () => {
    setExcludeExported(v => !v);
  }

  // update volume

  const updateMaxVolume = (fil, rec) => {
    if (!(fil)) {
      return;
    }
    setIsVolumeUpdating(true);
    const params = new URLSearchParams();

    params.set("filter", JSON.stringify({ ...fil, country: rec.country, leadType: rec.leadType }));
    params.set("country", recapLeadType.country);
    params.set("leadType", recapLeadType.leadType);
    params.set("excludeExported", excludeExported);

    fetch(API_BASE + "/leads/volume/?" + params.toString())
      .then(res => res.json())
      .then(res => {
        setMaxVolume(res.volume);
        setVolume((v) => Math.min(v, res.volume));
        setCostPerMile(res.costPerMile);
        setIsVolumeUpdating(false);
      })
  }

  useEffect(() => {
    updateMaxVolume(prevFilter, recapLeadType);
  }, [prevFilter, recapLeadType, excludeExported])

  // save order

  const [orderError, setOrderError] = useState("");

  const saveOrderHandler = ({ isPay }) => {
    fetch(API_BASE + "/orders/new/", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        volume: volume,
        filter: filter,
        country: recapLeadType.country,
        leadType: recapLeadType.leadType
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setOrderError(res.error)
          setTimeout(() => {
            setOrderError("")
          }, 3000);
          return console.log(res.error);
        }
        if (!(res.orderId)) {
          return;
        }
        if (isPay) {
          window.location.pathname = `/dashboard/orders/${res.orderId}/pay`
        } else {
          window.location.pathname = `/dashboard/orders/`
        }
      })
  }

  // clear tab

  const tabClearHandler = (tabValue) => {
    const tabFilters = criteriaTabs.find(t => t.value === tabValue).filters;
    setFilters((prevFilters) => {
      let newFilters = [...prevFilters];
      newFilters = newFilters.map(f => {
        if (!(tabFilters.includes(f.name))) {
          return f;
        }
        return getClearedFilter(f);
      })
      return newFilters;
    })
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "calc(100% - 260px)", height: "100%" }}>
      <NewOrderTopBar loading={filtersLoading || isVolumeUpdating} onSave={() => saveOrderHandler({ isPay: false })} />
      <div style={{
        display: "flex",
        gap: "20px",
        width: "100%",
        height: "100%",
        overflow: "auto",
        padding: "20px",
      }}>
        {filtersLoading && <FilterLoadingCard title="loading filters" />}
        {
          !filtersLoading &&
          <>
            <FilterFolder
              selectedOptionValue={selectedTab}
              options={criteriaTabs}
              onToggle={(value) => setSelectedTab(value)}
              onClear={tabClearHandler}
            >
              <div style={filtersLoading || isVolumeUpdating ? { opacity: "0.5", pointerEvents: "none" } : { opacity: "1" }}>
                {
                  selectedTab === "lead" &&
                  <FilterGroup>
                    <FilterParser filter={leadTypeFilters?.find(f => f.name === "lead-type")} filters={leadTypeFilters} setFilters={setLeadTypeFilters} />
                    {
                      leadSelectCriteria?.filter(c => c !== "key_c_birth_date")
                        .map(criteria => (
                          <FilterParser key={criteria} filter={filters?.find(f => f.name === criteria)} filters={filters} setFilters={setFilters} />
                        ))
                    }
                    {
                      leadSelectCriteria?.includes("key_c_birth_date") &&
                      <FilterCard title={"Date de Naissance"}>
                        <FilterLabel title={"A partir de"} />
                        <div style={{ height: "5px" }}></div>
                        <FilterInput icon={<PiCalendar />} type="text" placeholder={"dd-mm-yyyy"} value={startDate} onChange={(v) => onStartDateChange(v)} />
                        <div style={{ height: "10px" }}></div>
                        <FilterLabel title={"Jusqu'à"} />
                        <div style={{ height: "5px" }}></div>
                        <FilterInput icon={<PiCalendar />} type="text" placeholder={"dd-mm-yyyy"} value={endDate} onChange={(v) => onEndDateChange(v)} />
                      </FilterCard>
                    }
                  </FilterGroup>
                }
                {
                  selectedTab === "location" &&
                  <FilterGroup>
                    <FilterParser filter={leadTypeFilters?.find(f => f.name === "country")} filters={leadTypeFilters} setFilters={setLeadTypeFilters} />
                    {
                      locationCriteria?.filter(c => !["key_country", "key_c_zip"].includes(c))
                        .map(criteria => (
                          <FilterParser key={criteria} filter={filters?.find(f => f.name === criteria)} filters={filters} setFilters={setFilters} />
                        ))
                    }
                    {
                      locationCriteria?.includes("key_c_zip") &&
                      <FilterCard title={"Code Postal"}>
                        <FilterLabel title={"A partir de"} />
                        <div style={{ height: "5px" }}></div>
                        <FilterInput icon={<PiMapPin />} type="number" placeholder={"code"} value={startZip} onChange={(v) => onStartZipChange(v)} />
                        <div style={{ height: "10px" }}></div>
                        <FilterLabel title={"Jusqu'à"} />
                        <div style={{ height: "5px" }}></div>
                        <FilterInput icon={<PiMapPin />} type="number" placeholder={"code"} value={endZip} onChange={(v) => onEndZipChange(v)} />
                      </FilterCard>
                    }
                  </FilterGroup>
                }
              </div>
            </FilterFolder>
            <div style={{ maxWidth: "280px" }}>
              <FilterFolder
                noBorderRadiusTopRight
                selectedOptionValue={selectedRecapTab}
                options={recapTabs}
                onToggle={(value) => setSelectedRecapTab(value)}
              >
                {
                  selectedRecapTab === "recap" &&
                  <FilterGroup isVertical>
                    <FilterCard isFullWidth title="location recap">
                      <div style={{ display: 'flex', flexDirection: "column", gap: "20px" }}>
                        {
                          locationCriteria.map(criteria => (
                            !!(recapData.location[criteria]?.length) &&
                            <FilterCollapsable key={criteria} isCollapsable label={`${criteriaToTitleMap[criteria] || criteria} (${recapData.location[criteria]?.length})`}>
                              <FilterList items={recapData.location[criteria]?.map(c => c.title)} />
                            </FilterCollapsable>
                          ))
                        }
                      </div>
                    </FilterCard>
                    <FilterCard isFullWidth title="lead recap">
                      <div style={{ display: 'flex', flexDirection: "column", gap: "20px" }}>
                        {
                          leadSelectCriteria.map(criteria => (
                            !!(recapData.lead[criteria]?.length) &&
                            <FilterCollapsable key={criteria} isCollapsable label={`${criteriaToTitleMap[criteria] || criteria} (${recapData.lead[criteria]?.length})`}>
                              <FilterList items={recapData.lead[criteria]?.map(c => c.title)} />
                            </FilterCollapsable>
                          ))
                        }
                      </div>
                    </FilterCard>
                  </FilterGroup>
                }
                {
                  selectedRecapTab === "order" &&
                  <FilterGroup isVertical>
                    <div style={isVolumeUpdating ? { opacity: "0.5", pointerEvents: "none" } : { opacity: "1" }}>
                      <FilterCard isFullWidth title="Purchase" cardStyle={"special"}>
                        <div style={{ display: 'flex', flexDirection: "column", gap: "20px" }}>
                          <FilterCollapsable noMaxHeight label={`Volume (max: ${maxVolume})`}>
                            <FilterRange
                              isExactValue
                              value={volume}
                              start={0}
                              end={maxVolume}
                              onChange={(value) => {
                                setVolume(value);
                              }}
                            />
                          </FilterCollapsable>
                          <FilterSelectOption isSelected={!excludeExported} title={"Include my exported leads"} onSelect={onToggleExcludeExported} />
                          <FilterCollapsable label={`Price (€${PRICE_CPM} CPM)`}>
                            <div style={{ display: "flex", gap: "5px" }}>
                              <FilterTag title={`€${((volume / 1000) * PRICE_CPM).toFixed(2)}`} />
                              <FilterAction title={"pay"} onClick={() => { saveOrderHandler({ isPay: true }) }} />
                            </div>
                          </FilterCollapsable>
                          {
                            orderError &&
                            <FilterFormMessage type="error" message={orderError} />
                          }
                        </div>
                      </FilterCard>
                    </div>
                  </FilterGroup>
                }
              </FilterFolder>
            </div>
          </>
        }
      </div>
    </div>
  )
}

const NewOrderTopBar = ({ loading, onSave }) => {
  return (
    <DashboardTopBar>
      <div style={{ display: 'flex', width: "100%" }}>
        <h2 style={{ marginRight: "auto" }}>New Order</h2>
        {
          !loading &&
          <FilterAction title={"save order"} onClick={onSave} />
        }
      </div>
    </DashboardTopBar>
  )
}

export default NewOrderSection