import { useRef, useState } from "react"
import FilterButton from "./FilterButton";
import { ReactComponent as CloseIcon } from "./icon-close-6.svg"
import { ReactComponent as SearchIcon } from "./icon-search-12.svg"
import "./FilterSearchBar.scss"

function FilterSearchBar({ value, onChange }) {
  const [isInputFocus, setIsInputFocus] = useState(false);
  const classList = ["comp-filter-search-bar"]
  isInputFocus && classList.push("focus");
  const inputRef = useRef();
  const randomId = useRef(crypto.randomUUID());

  return (
    <div className={classList.join(" ")}>
      <input
        ref={inputRef}
        id={randomId.current}
        type="text"
        className="input"
        placeholder="search" value={value}
        onChange={(e) => onChange(e.target.value || "")}
        onFocus={() => { setIsInputFocus(true) }}
        onBlur={() => { setIsInputFocus(false) }}
      />
      {
        value &&
        <FilterButton icon={<CloseIcon />} onClick={() => { onChange("") }} />
      }
      {
        !value &&
        <FilterButton icon={<SearchIcon />} onClick={() => { inputRef.current.focus(); setIsInputFocus(true) }} />
      }
    </div>
  )
}

export default FilterSearchBar