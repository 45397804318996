import { useEffect, useState } from "react"
import { useQuery } from "react-query";
import FilterAction from "../components/Filter/FilterAction"
import { DashboardTopBar } from "../templates/Dashboard"
import config from "../config";
import FilterOrderCard from "../components/Filter/FilterOrderCard";
import FilterGrid from "../components/Filter/FilterGrid";
import FilterGridCell from "../components/Filter/FilterGridCell";
import FilterSearchBar from "../components/Filter/FilterSearchBar";
import FilterRadioSelect from "../components/Filter/FilterRadioSelect";
import FilterCard from "../components/Filter/FilterCard";
import FilterGroup from "../components/Filter/FilterGroup";
import FilterFormMessage from "../components/Filter/FilterFormMessage";
import FilterImageBox from "../components/Filter/FilterImageBox";

const ENV = config.ENV;
const API_BASE = ENV === "production" ? config.API_BASE_PROD : config.API_BASE_DEV;

function AllOrdersSection() {

  const [orders, setOrders] = useState([]);
  const [usernameQuery, setUsernameQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [payment, setPayment] = useState("");
  const [exportFilter, setExportFilter] = useState("");
  const [isAttachmentVisible, setIsAttachmentVisible] = useState(false);
  const [attachmentSrc, setAttachmentSrc] = useState("");

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams();
      params.set("usernameQuery", usernameQuery);
      const target = API_BASE + "/users/search/?" + params.toString();
      let fetchedUsers;
      try {
        fetchedUsers = (await fetch(target)).json();
      } catch (error) {
        console.log(error);
      }

      if ((await fetchedUsers).error) {
        return console.log((await fetchedUsers).error);
      }

      setUsers((await fetchedUsers));

    })();
  }, [usernameQuery])

  const onUserSelect = (userId) => {
    setUserId(userId);
  }

  const fetchOrders = async () => {
    const params = new URLSearchParams();
    params.set("payment", JSON.stringify(payment));
    params.set("export", JSON.stringify(exportFilter));
    params.set("userId", userId);
    const target = API_BASE + "/orders/all/?" + params.toString();
    let fetchedOrders;
    try {
      fetchedOrders = (await fetch(target)).json();
    } catch (error) {
      console.log(error);
    }

    if ((await fetchedOrders).error) {
      return console.log((await fetchedOrders).error);
    }

    return await fetchedOrders;
  }

  const ordersQuery = useQuery('ordersData', () => fetchOrders(), {
    onError: (err) => {
      console.log("error fetching order");
      console.log(err);
    }
  });

  const ordersData = ordersQuery.data;
  const ordersRefetch = ordersQuery.refetch;

  useEffect(() => {
    if (ordersData) {
      setOrders(ordersData);
    }
  }, [ordersData])

  useEffect(() => {
    ordersRefetch();
  }, [exportFilter, userId, payment, ordersRefetch])

  const exportHandler = (orderId) => {
    setOrders((prevOrders) => {
      const newOrders = { ...prevOrders };
      const order = newOrders.list.find(o => o.orderId === orderId);
      order.isLoading = true;
      return newOrders;
    })
    fetch(API_BASE + "/leads/export", {
      method: "post",
      body: JSON.stringify({ orderId: orderId }),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(() => {
        ordersQuery.refresh()
      });
  }

  const downloadHandler = (order, isCSV) => {
    const params = new URLSearchParams();
    params.set("orderId", order.orderId);
    isCSV && params.set("type", "csv");
    const linkElement = document.createElement("a");
    linkElement.setAttribute("download", "");
    linkElement.href = API_BASE + "/leads/download/?" + params.toString();
    console.log(linkElement.href);
    document.body.appendChild(linkElement);
    linkElement.click();
    linkElement.remove();
  }

  const onShowAttachment = (order) => {
    const params = new URLSearchParams();
    params.set("orderId", order.orderId);
    const src = API_BASE + "/orders/attachment/?" + params.toString();
    
    if (order.attachment.extension === "pdf") {
      onViewAttachment(src);
      return;
    }
    setAttachmentSrc(src);
    setIsAttachmentVisible(true);
  }

  const onViewAttachment = (src) => {
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = src;
    a.click();
  }

  const onMarkAsPaid = (order) => {
    setOrders((prevOrders) => {
      const newOrders = { ...prevOrders };
      const foundOrder = newOrders.list.find(o => o.orderId === order.orderId);
      foundOrder.isLoading = true;
      return newOrders;
    })
    const target = API_BASE + "/orders/confirm/";
    fetch(target, {
      method: "post",
      body: JSON.stringify({
        orderId: order.orderId
      }),
      headers: {
        "content-type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          setOrders((prevOrders) => {
            const newOrders = { ...prevOrders };
            const foundOrder = newOrders.list.find(o => o.orderId === order.orderId);
            foundOrder.isLoading = false;
            return newOrders;
          })
          return console.log(res.error);
        } else {
          console.log(res.success);
        }
        console.log("refereshing...");
        ordersQuery.refetch();
      })
      .catch((error) => {
        console.log(error.message);
        setOrders((prevOrders) => {
          const newOrders = { ...prevOrders };
          const foundOrder = newOrders.list.find(o => o.orderId === order.orderId);
          foundOrder.isLoading = false;
          return newOrders;
        })
      })
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "calc(100% - 260px)", height: "100%" }}>
      <DashboardTopBar>
        <h2 style={{ marginRight: "auto" }}>All Orders</h2>
        <FilterAction title="new order" onClick={() => { window.location.pathname = "/dashboard/orders/new" }} />
      </DashboardTopBar>
      <div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
        <div style={{ width: "100%", height: "100%", overflow: "auto", padding: "20px", "--scrollbar-filter-bg": "var(--filter-ui-93)" }}>
          <FilterGrid gutter={10} maxColumns={3}>
            {
              !(orders.list?.length) && !ordersQuery.isLoading &&
              <FilterGridCell size={1}>
                <FilterFormMessage message={"no orders with the specified criteria"} type="error" />
              </FilterGridCell>
            }
            {
              ordersQuery.isLoading &&
              <FilterGridCell size={1}>
                <FilterFormMessage message={"loading..."} type="success" />
              </FilterGridCell>
            }
            {
              ordersQuery.isSuccess &&
              orders.list?.map((order) => (
                <FilterGridCell size={1} key={order.orderId}>
                  <FilterOrderCard
                    key={order.orderId}
                    order={{ ...order, username: orders.users?.find(u => u.userId === order.metadata.userId).username }}
                    onExport={exportHandler}
                    onDownload={downloadHandler}
                    onMarkAsPaid={onMarkAsPaid}
                    onViewAttachment={onShowAttachment}
                  />
                </FilterGridCell>
              ))
            }
          </FilterGrid>
        </div>
        <div style={{ width: "fit-content", height: "100%", overflow: "auto", backgroundColor: "var(--filter-ui-95)", flexShrink: "0", padding: "10px", borderLeft: "1px solid var(--filter-ui-99)" }}>
          {
            isAttachmentVisible &&
            <>
              <div style={{ display: "flex", gap: "5px" }}>
                <FilterAction variation="blue" title={"hide"} onClick={() => setIsAttachmentVisible(false)} />
                <FilterAction variation="blue" title={"view"} onClick={() => onViewAttachment(attachmentSrc)} />
              </div>
              <FilterImageBox src={attachmentSrc} maxHeight={"300px"} maxWidth={"300px"} />
            </>
          }
          {
            !isAttachmentVisible &&
            <FilterGroup isVertical={true}>
              <FilterCard title={"username"}>
                <FilterRadioSelect
                  options={[{ value: "", title: "all" }]
                    .concat(users.map(u => ({ value: u.userId, title: u.username })))}
                  onSelect={onUserSelect}
                  onFocus={onUserSelect}
                  focusOptionValue={userId}
                />
                <div style={{ height: "15px" }}></div>
                <FilterSearchBar value={usernameQuery} onChange={setUsernameQuery} />
              </FilterCard>
              <FilterCard title={"payment method"}>
                <FilterRadioSelect
                  options={[
                    { value: "", title: "all" },
                    { value: "stripe", title: "online" },
                    { value: "offline", title: "offline" },
                  ]}
                  onSelect={(value) => { setPayment(p => ({ ...p, method: value })) }}
                  onFocus={(value) => { setPayment(p => ({ ...p, method: value })) }}
                  focusOptionValue={payment.method || ""}
                />
              </FilterCard>
              <FilterCard title={"payment status"}>
                <FilterRadioSelect
                  options={[
                    { value: "", title: "all" },
                    { value: "initial", title: "unpaid" },
                    { value: "pending", title: "pending" },
                    { value: "done", title: "paid" },
                  ]}
                  onSelect={(value) => { setPayment(p => ({ ...p, status: value })) }}
                  onFocus={(value) => { setPayment(p => ({ ...p, status: value })) }}
                  focusOptionValue={payment.status || ""}
                />
              </FilterCard>
              <FilterCard title={"export status"}>
                <FilterRadioSelect
                  options={[
                    { value: "", title: "all" },
                    { value: "initial", title: "not exported" },
                    { value: "pending", title: "pending" },
                    { value: "done", title: "exported" },
                  ]}
                  onSelect={(value) => { setExportFilter(e => ({ ...e, status: value })) }}
                  onFocus={(value) => { setExportFilter(e => ({ ...e, status: value })) }}
                  focusOptionValue={exportFilter.status || ""}
                />
              </FilterCard>
            </FilterGroup>
          }
        </div>
      </div>
    </div>
  )
}

export default AllOrdersSection