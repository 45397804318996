import { useContext, useEffect } from 'react';
import FilterTag from '../components/Filter/FilterTag';
import FilterAction from '../components/Filter/FilterAction';
import { appGlobalContext } from "../App";
import { Outlet, useNavigate } from 'react-router-dom';
import FilterNavLink from '../components/Filter/FilterNavLink';
import config from "../config";
const { navLinks } = config;

const ENV = config.ENV;
const API_BASE = ENV === "production" ? config.API_BASE_PROD : config.API_BASE_DEV;

function Dashboard() {

  const { appGlobalData, setAppGlobalData } = useContext(appGlobalContext);
  const navigate = useNavigate();


  // fetch user
  useEffect(() => {
    fetch(API_BASE + "/auth/user")
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          console.log(res.error)
          navigate("/login");
        } else {
          setAppGlobalData(data => ({ ...data, user: res }))
        }
      })
  }, [navigate, setAppGlobalData])

  return (
    <div className='hxp-scrollbar-filter hxp-dashboard'
      style={
        {
          display: "flex",
          overflow: "auto",
          height: "100%",
          width: "100%",
          "--scrollbar-filter-bg": "var(--filter-ui-95)",
          backgroundColor: "var(--filter-ui-93)",
        }
      }
    >
      {
        appGlobalData.user &&
        <>
          <DashboardSideBar />
          <Outlet />
        </>
      }
    </div>
  )
}

const DashboardSideBar = () => {

  const { appGlobalData } = useContext(appGlobalContext);

  const trimEndSlash = (str) => {
    return str.endsWith('/') ?
      str.slice(0, -1) :
      str;
  };

  const logoutHandler = () => {
    fetch(API_BASE + "/auth/logout", {
      method: "post"
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          window.location.pathname = "/login"
        }
      })
  }

  const navLinkClick = (path) => {
    window.location.pathname = path;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "260px", minWidth: "260px", alignItems: "flex-start", height: "100%", backgroundColor: "var(--filter-ui-95)", boxShadow: "inset -1px 0 0 0 var(--filter-ui-99)" }}>
      {
        appGlobalData?.user &&
        <div style={{ display: "flex", gap: "5px", padding: "15px", width: "100%", boxShadow: "inset 0 -1px 0 0 var(--filter-ui-99)" }}>
          <FilterTag title={appGlobalData.user.username} />
          <FilterAction variation='blue' title={"logout"} onClick={logoutHandler} />
        </div>
      }
      <div style={{ display: "flex", flexDirection: "column", padding: "15px", width: "100%", gap: "5px" }}>
        {
          (appGlobalData.user?.isAdmin ? navLinks : navLinks.filter(l => !l.isAdminRoute)).map((link, index) => (
            <FilterNavLink
              key={index}
              title={link.title}
              isActive={link.isExact ? trimEndSlash(window.location.pathname) === trimEndSlash(link.path) : window.location.pathname.startsWith(link.path)}
              onClick={() => { navLinkClick(link.path) }}
            />
          ))
        }
      </div>
    </div>
  )
}

// const SessionsSection = () => {

//   const [sessions, setSessions] = useState([
//     { _id: 1, lastValidation: "24-04-2015", isLoggedIn: true },
//     { _id: 2, lastValidation: "24-04-2015", isLoggedIn: false },
//   ]);

//   // get sessions
//   useEffect(() => {
//     fetch(API_BASE + "/auth/sessions", {
//       headers: {
//         "Content-Type": "application/json"
//       }
//     })
//       .then(res => res.json())
//       .then((json) => {
//         if (json.error) {
//           return console.log(json.error);
//         }
//         setSessions(json);
//       });
//   }, [])

//   return (
//     <div style={{ display: "flex", flexDirection: "column", width: "calc(100% - 260px)", height: "100%" }}>
//       <DashboardTopBar>
//         <h2 style={{ marginRight: "auto" }}>sessions</h2>
//         {/* <FilterAction title="new order" onClick={() => { window.location.pathname = "/dashboard/orders/new" }} /> */}
//       </DashboardTopBar>
//       <div style={{ display: 'flex', flexDirection: "column", gap: "10px" }}>
//         {
//           sessions.map((s, i) => (
//             <div key={i} style={{ display: "flex", flexDirection: "column", padding: "10px", border: "1px solid var(--filter-ui-99)", borderRadius: "5px", backgroundColor: "var(--filter-ui-95)" }}>
//               <p>{s.isLoggedIn ? "🟢" : "🔴"} {s._id}</p>
//               <p>last validation: {s.lastValidation}</p>
//             </div>
//           ))
//         }
//       </div>
//     </div>
//   )
// }

export const DashboardTopBar = ({ children }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", padding: "0 20px", width: "100%", height: "60px", minHeight: "60px", backgroundColor: "var(--filter-ui-95)", borderBottom: "1px solid var(--filter-ui-99)" }}>
      {children}
    </div>
  )
}

export default Dashboard;