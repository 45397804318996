import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../config';
import CheckoutForm from '../components/Filter/CheckoutForm';
import { useEffect, useState } from 'react';
import { DashboardTopBar } from '../templates/Dashboard';
import FilterRadioSelect from '../components/Filter/FilterRadioSelect';
import FilterCard from '../components/Filter/FilterCard';
// import FilterList from '../components/Filter/FilterList';
import FilterFieldAction from '../components/Filter/FilterFieldAction';
import FilterInputUpload from '../components/Filter/FilterInputUpload';
import FilterAction from '../components/Filter/FilterAction';
import FilterSelectThumb from '../components/Filter/FilterSelectThumb';
import { useParams } from 'react-router-dom';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const ENV = config.ENV;
const API_BASE = ENV === "production" ? config.API_BASE_PROD : config.API_BASE_DEV;
const STRIPE_PK = ENV === "production" ? config.STRIPE_PK_LIVE : config.STRIPE_PK_TEST;
const stripePromise = loadStripe(STRIPE_PK);

function PaymentSection() {

  const orderId = useParams().orderId;

  const [clientSecret, setClientSecret] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const [offlinePayments, setOfflinePayments] = useState([]);
  const [selectedOfflinePayment, setSelectedOfflinePayment] = useState();
  const [selectedAttachmentFile, setSelectedAttachmentFile] = useState();
  const [isAttachCardLoading, setIsAttachCardLoading] = useState(false);
  const [orderData, setOrderData] = useState();

  const offlinePaymentData = offlinePayments.find(p => p.title === selectedOfflinePayment);

  useEffect(() => {
    const target = API_BASE + "/payments/list";
    fetch(target)
      .then(res => res.json())
      .then((result) => {
        setOfflinePayments(result);
        setSelectedOfflinePayment(result[0].title)
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("orderId", orderId);
    const target = API_BASE + "/orders/info/?" + params.toString();

    fetch(target)
      .then(res => res.json())
      .then((result) => {
        setOrderData(result);
      })
      .catch((error) => {
        console.log(error);
      })
  }, [orderId])

  const onOfflinePaymentSelect = (value) => {
    setSelectedOfflinePayment(value)
  }

  const onPaymentMethodSelect = (value) => {
    setPaymentMethod(value)
  }

  const onAttach = async () => {
    if (!selectedAttachmentFile) {
      return console.log("no file selected");
    }

    setIsAttachCardLoading(true);
    const formData = new FormData();
    formData.append("file", selectedAttachmentFile);

    const mediaTarget = API_BASE + "/media/upload";
    let mediaResponse;
    try {
      mediaResponse = await fetch(mediaTarget, {
        method: "post",
        body: formData,
      })
    } catch (error) {
      console.log("error uploading attachment");
      console.log(error.message);
      setIsAttachCardLoading(false);
    }
    mediaResponse = await mediaResponse.json();

    if (mediaResponse.error) {
      setIsAttachCardLoading(false);
      return console.log(mediaResponse.error);
    }

    const mediaId = mediaResponse.data.mediaId;
    let attachmentResponse;
    const attachmentTarget = API_BASE + "/orders/attach";


    try {
      attachmentResponse = await fetch(attachmentTarget, {
        method: "post",
        body: JSON.stringify({
          orderId: orderId,
          attachmentId: mediaId
        }),
        headers: {
          "content-type": "application/json"
        }
      })
    } catch (error) {
      console.log("error attaching file to order");
      console.log(error.message);
      setIsAttachCardLoading(false);
    }

    attachmentResponse = await attachmentResponse.json();

    if (attachmentResponse.error) {
      setIsAttachCardLoading(false);
      return console.log(attachmentResponse.error);
    }

    window.location = "/dashboard/orders";
    setIsAttachCardLoading(false);
  }

  useEffect(() => {
    // Get the client secret by orderId
    const params = new URLSearchParams();
    params.set("orderId", orderId);
    fetch(API_BASE + "/orders/pay/?" + params.toString(), {
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return console.log(data.error);
        }
        setClientSecret(data.clientSecret);
      });
  }, [orderId]);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
    appearance: {
      variables: {
        fontSizeBase: "0.8rem"
      }
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", overflow: "hidden" }}>
      <DashboardTopBar>
        <h2>Pay order {orderId}</h2>
      </DashboardTopBar>
      <div style={{ display: "flex", gap: "10px", overflow: "auto", height: "100%", width: "100%", padding: "20px", backgroundColor: "var(--filter-ui-95)" }}>
        {
          orderData?.payment?.status === "initial" &&
          <>

            <FilterCard title={"Payment Method"}>
              <FilterRadioSelect
                isParent={true}
                onSelect={onPaymentMethodSelect}
                onFocus={onPaymentMethodSelect}
                focusOptionValue={paymentMethod}
                options={[
                  { title: "stripe", value: "stripe" },
                  { title: "offline", value: "offline" }
                ]}
              />
            </FilterCard>
            {
              paymentMethod === "stripe" &&
              clientSecret &&
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm orderId={orderId} />
              </Elements>
            }
            {
              paymentMethod === "offline" &&
              offlinePayments?.length &&
              <>
                <FilterCard title={"Bank"}>
                  {/* <FilterRadioSelect
                    isParent={true}
                    onSelect={onOfflinePaymentSelect}
                    onFocus={onOfflinePaymentSelect}
                    focusOptionValue={selectedOfflinePayment}
                    options={offlinePayments.map(p => ({ title: p.title, value: p.title }))}
                  /> */}
                  <FilterSelectThumb
                    focusOptionValue={selectedOfflinePayment}
                    onSelect={onOfflinePaymentSelect}
                    options={offlinePayments.map(p => ({ title: p.title, value: p.title, thumbnailSrc: `${API_BASE}/media/get?uuid=${p.logoUUID}` }))}
                  />
                </FilterCard>
                <div style={{ minWidth: "320px" }}>
                  <FilterCard title={"Bank Info"} isFullWidth={true} isFullHeight={true}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "5px", overflow: 'auto', height: "100%" }}>
                      {
                        offlinePaymentData.fields.map(f => (
                          <FilterFieldAction key={f.name} name={f.name} value={f.value} action={{ title: "click to copy", onClick: () => { navigator.clipboard.writeText(f.value) }, feedback: "copied" }} />
                        ))
                      }
                      {
                        offlinePaymentData.scanContent &&
                        <FilterFieldAction
                          name={"QR code"}
                          value={
                            <div style={{ height: "150px", width: "150px", border: "1px solid var(--filter-ui-95)", borderRadius: "5px", overflow: "hidden" }}>
                              <img style={{ height: "100%", width: "100%" }} src={offlinePaymentData.scanContent} alt='' />
                            </div>
                          }
                        />
                      }
                    </div>
                  </FilterCard>
                </div>
                <FilterCard title="َAttach Receipt">
                  <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "flex-start", opacity: `${isAttachCardLoading ? "0.5" : "1"}` }}>
                    <FilterInputUpload
                      file={selectedAttachmentFile}
                      placeholder={"no file"}
                      selectAction={"select file"}
                      changeAction={"change file"}
                      onSelect={(file) => { setSelectedAttachmentFile(file); console.log(file) }}
                    />
                    <FilterAction title={"Attach"} onClick={onAttach} />
                  </div>
                </FilterCard>
              </>
            }
          </>
        }
        {
          orderData?.payment?.status && orderData?.payment?.status !== "initial" &&
          <p>order status: {orderData.payment.status}</p>
        }
      </div>
    </div>
  );
}

export default PaymentSection